import * as echarts from 'echarts/core';
import { TitleComponent, TooltipComponent, GridComponent, LegendComponent, ToolboxComponent } from 'echarts/components';
import { BarChart, LineChart, PictorialBarChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { color } from 'echarts';
echarts.use([
    TooltipComponent,
    LineChart,
    GridComponent,
    TitleComponent,
    BarChart,
    CanvasRenderer,
    UniversalTransition,
    LegendComponent,
    ToolboxComponent,
    PictorialBarChart,
]);

const getTitle = (title) => {
    return {
        show: title ? true : false,
        text: title,
        padding: [20, 20, 10, 0],
        textStyle: { fontSize: 14, }
    }
}

export const rankingChars = (dom, data, title) => {
    if (!dom) return;
    dom.removeAttribute('_echarts_instance_');
    const myChart = echarts.init(dom);
    let dataAll = [];
    let yAxisData = [];
    if (data.length) {
        data.map(it => {
            for (const key in it) {
                dataAll.unshift(it[key]);
                yAxisData.unshift(key.length > 5 ? key.substring(0, 5) + '...' : key);
            }
        })
    } else {
        dataAll.push(0)
        yAxisData.push('暂无数据')
    }

    const max = []

    dataAll.map(() => max.push(dataAll[dataAll.length - 1]))

    const option = {
        backgroundColor: "transparent",
        grid: [{ x: "25%", y: '15%', bottom: 10, }],
        title: getTitle(title),
        xAxis: [
            {
                gridIndex: 0,
                axisTick: { show: false },
                axisLabel: { show: false },
                splitLine: { show: false },
                axisLine: { show: false },
            },
        ],
        yAxis: [
            {
                gridIndex: 0,
                interval: 0,
                data: yAxisData,
                axisTick: { show: false },
                axisLabel: { show: true },
                splitLine: { show: false },
                axisLine: { show: false, lineStyle: { color: "#6173a3" } },
            },
            {
                triggerEvent: true,
                show: true,
                inverse: false,
                data: dataAll,
                axisLine: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    interval: 0,
                    shadowOffsetX: "-20px",
                    color: ["#fff"],
                    align: "left",
                    verticalAlign: "center",
                    lineHeight: 40,
                    fontSize: 13,
                },
            },
        ],
        series: [
            {
                name: "排名TOP10",
                type: "bar",
                xAxisIndex: 0,
                yAxisIndex: 0,
                barWidth: 15,
                itemStyle: {
                    color: "#86c9f4",
                    borderRadius: 15,
                },
                label: {
                    show: false,
                    position: "right",
                    color: "#9EA7C4",
                },
                data: dataAll,
            },
            {
                name: "最大值背景条",
                type: "bar",
                barWidth: 15,
                barGap: "-100%",
                margin: "20",
                data: max,
                textStyle: {
                    //图例文字的样式
                    fontSize: 10,
                    color: "#fff",
                },
                itemStyle: {
                    color: "rgba(134, 201, 244, .5)",
                    //width:"100%",
                    fontSize: 10,
                    borderRadius: 15,
                },
            },
        ],
    };
    myChart.clear();
    option && myChart.setOption(option);
    return option
}

/**
 * 
 * @param {document} dom 
 * @param {object} data 
 * @param {string} title 
 * @param {date} day 
 * @returns 
 */
export const lineChart = (dom, data, title, day) => {
    if (!dom) return;
    dom.removeAttribute('_echarts_instance_');
    const myChart = echarts.init(dom);
    let xAxisData = [];
    let yAxisData = [];
    if (data.length) {
        data.map(it => {
            for (const key in it) {
                yAxisData.push(it[key]);
                key.length > 10 ? xAxisData.push(key.substring(0, 3) + '...' + key.substring(key.length - 3, key.length)) : xAxisData.push(key);
            }
        })
    } else {
        yAxisData.push('暂无数据')
        xAxisData.push('暂无数据')
    }
    const option = {
        title: getTitle(title),
        tooltip: {
            trigger: "axis",
            formatter: (value, index) => {
                let name = value[0].name
                const dateRegex = /\d{4}-\d{2}-\d{2}/; // 假设日期格式为YYYY-MM-DD
                if (!dateRegex.test(name)) {
                    name += ':00'
                    return `${name}<br/>${value[0].marker}${day} <span style="font-weight:900;margin-left:15px">${value[0].data}</span>`
                }
                return `${name}<br/>${value[0].marker}<span style="font-weight:900;margin-left:5px">${value[0].data}</span>`
            }
        },
        grid: {
            left: "0",
            right: "0",
            bottom: "20",
            top: title ? '70' : "30",
            containLabel: true,
        },
        xAxis: [
            {
                type: "category",
                data: xAxisData,
                axisLine: {
                    lineStyle: {
                        color: "#999",
                    },
                },
                axisLabel: {
                    formatter: (value) => {
                        const dateRegex = /\d{4}-\d{2}-\d{2}/; // 假设日期格式为YYYY-MM-DD
                        if (dateRegex.test(value)) {
                            const date = `${value.split('-')[1]}-${value.split('-')[2]}`
                            return date
                        }
                        return value;
                    }
                }

            },
        ],
        yAxis: [
            {
                type: "value",
                splitNumber: 3,
                splitLine: {
                    lineStyle: {
                        color: "#DDD",
                    },
                },
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: "#333",
                    },
                },
                nameTextStyle: {
                    color: "#999",
                },
                splitArea: {
                    show: false,
                },

            },
        ],
        series: [
            {
                type: "line",
                data: yAxisData,
                symbol: 'none',
                lineStyle: {
                    width: 2,
                    color: "#0793c1"
                },
                itemStyle: {
                    show: false,
                    color: "#0793c1",
                    borderWidth: 1,
                    /*shadowColor: 'rgba(72,216,191, 0.3)',
                          shadowBlur: 100,*/
                    borderColor: "#A9F387",
                },
                smooth: true,
            },
        ],
    };
    myChart.clear();
    option && myChart.setOption(option);
    return option
}

export const barChart = (dom, data, title) => {
    if (!dom) return;
    dom.removeAttribute('_echarts_instance_');
    const myChart = echarts.init(dom);
    let xAxisData = [];
    let yAxisData = [];
    if (data.length) {
        data.map(it => {
            for (const key in it) {
                yAxisData.push(it[key]);
                xAxisData.push(key);
            }
        })
    } else {
        xAxisData.push('暂无数据')
        yAxisData.push(0)
    }

    const result = yAxisData.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
    }, 0);

    const option = {
        title: getTitle(title),
        grid: {
            left: "0",
            right: "100",
            bottom: "30",
            top: title ? '60' : "20",
            containLabel: true,
        },
        tooltip: {
            show: false,
            trigger: "axis",
            axisPointer: {
                type: "none",
            },
        },
        backgroundColor: "transparent",
        xAxis: {
            show: false,
            type: "value",
            axisLabel: {
                margin: -20,
            }
        },
        yAxis: [
            {
                type: "category",
                inverse: true,
                axisLabel: {
                    show: true,
                    margin: 15,
                    fontSize: "14",
                    color: '#000',
                    formatter: (value) => {
                        if (value.length >= 7) return value.substring(0, 3) + '...' + value.substring(value.length - 3, value.length)
                        return value;
                    }

                },
                splitLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        borderColor: '#d8d8d8',
                        width: 1,
                    }
                },
                data: xAxisData,
            },

        ],
        series: [
            {
                name: "销售量",
                type: "bar",
                zlevel: 1,
                itemStyle: {
                    borderRadius: [0, 30, 30, 0],
                    color: '#0793c1',
                    borderColor: 'none',
                },
                label: {
                    show: true,
                    position: 'right',
                    right: 20,
                    fontSize: "14",
                    formatter: "{c}",
                },
                barWidth: 8,
                data: yAxisData,
            },
            {
                name: "背景",
                type: "bar",
                barGap: "-100%",
                data: yAxisData,
                barWidth: 4,
                itemStyle: {
                    color: "#fff",
                    borderRadius: 30,
                },
                label: {
                    show: true,
                    position: 'right',
                    fontSize: "14",
                    color: '#b5b5b5',
                    formatter: (value) => {
                        if (!result) return '';
                        let s = ``
                        for (let i = 0; i < value.data.toString().length; i++) {
                            s += '   '
                        }
                        return `${s}|  ${(value.data / result * 100).toFixed(2)}%`
                    },
                },
            },
        ],
    };

    myChart.clear();
    option && myChart.setOption(option);
    return option
}