import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("iframe", {
    id: "wxlogin",
    sandbox: "allow-scripts allow-top-navigation",
    scrolling: "no",
    width: "300",
    height: "400",
    frameBorder: "0",
    allowTransparency: "true",
    src: $options.setSrc
  }, null, 8, _hoisted_1)]);
}