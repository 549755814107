// const BASE_URL = 'http://192.168.31.35:3002';
const BASE_URL = 'https://dc-server.kuaiyicn.com';
// const BASE_URL = 'http://192.168.205.164:3002';
export default {
    server: {
        URL: BASE_URL,
        LOG: false,
        VERSION: "1.1.2", //服务器可用版本
        VERSIONS: "1.0.0", //提示文本控制
        VERSIONINTRO: "DEV TEST ING.", //提示文本
        hardwareConcurrency:navigator.hardwareConcurrency, //cup核心数
        screen: JSON.stringify(window.screen), //屏幕分辨率
        platform:navigator.platform
    },
    udp: {
        IP: BASE_URL,
    },
    TOKEN: '',
    aes: {
        SECRETKEY: "",
        IV: ""
    },
    cryptoJSKey: 'admin_kyxy'

}
