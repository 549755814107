import notfound from "./page/404.vue";
import {
    createRouter,
    createWebHashHistory
} from 'vue-router';

const routes = [{
    path: '/login',
    component: require("./page/login").default,
    name: "登录"
}, {
    path: '/identify',
    component: require("./page/identify").default,
    name: "选择身份"
}, {
    path: '/404',
    component: notfound,
},
{
    path: '/',
    component: require("./page/index.vue").default,
    name: "数据中心",
    children: [
        {
            path: '/home',
            name: '数据中心',
            component: require("./page/teamhome").default
        },
        {
            path: '/shop/:id',
            name: '经营数据',
            component: require("./page/shophome").default
        },
        {
            path: '/orders',
            name: '订单详情',
            component: require("./page/orders").default
        },
        {
            path: '/shops',
            name: '店铺总览',
            component: require("./page/shops").default
        },
        {
            path: '/:catchAll(.*)',
            // name: '404',
            // component: notfound
            redirect: "/login"
        },
        {
            path: '/reconciliation',
            "name": '交易对账',
            "component": require("./page/reconciliation").default
        },

    ]
},
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router



