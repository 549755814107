import http from '../../helper/kyhttp';
import util from "../../util";

const getShops = (data) => {
    return http.request('/shop/', 1, { ...data })
}

const getBaseShop = (data) => {
    return http.request('/shop/getBaseShop', 1, { ...data })
}

export default {
    getShops,
    getBaseShop
}