export let orderStatus = [
    {
        value: 'CREATED',
        label: '已创建',
    },
    {
        value: 'PAID',
        label: '已支付',
    },
    {
        value: 'CANCELED',
        label: '已取消',
    },
    {
        value: 'COMPLETED',
        label: '已完成',
    },
    {
        value: 'REVIEW',
        label: '已评价',
    },
]

export let routerStatus = [
    {
        label: '',
        options: [
            {
                value: 'CREATED',
                label: '已创建',
            },
            {
                value: 'PAID',
                label: '已支付',
            },
            {
                value: 'TOOK',
                label: '商家已接单',
            },
            {
                value: 'COOK',
                label: '商家已出餐',
            },
            {
                value: 'PICKED',
                label: '分拣站已领取',
            },
            {
                value: 'FACE',
                label: '已面交通知',
            },
            {
                value: 'PUT',
                label: '已入柜',
            },
            {
                value: 'TAKEFOOD',
                label: '已取餐',
            },
            {
                value: 'TAKEFACE',
                label: '已面交核销',
            },
            {
                value: 'COMPLETED',
                label: '已完成',
            }]
    },
    {
        label: '',
        options: [{
            value: 'CANCELED',
            label: '已取消',
        },
        {
            value: 'LOCKED',
            label: '已锁定',
        },
        {
            value: 'RECYCLED',
            label: '已回收',
        },
        {
            value: 'CLEAN',
            label: '已清理',
        },
        {
            value: 'REFUNDING',
            label: '退款中',
        },
        {
            value: 'REFUNDPASS',
            label: '退款成功',
        },
        {
            value: 'REFUNDFAIL',
            label: '退款失败',
        },
        {
            value: 'REFUNDEND',
            label: '退款已到账',
        }]
    }
]

export let typeList = [
    {
        label: '外卖',
        value: 'SHOP_DELIVERY'
    },
    {
        label: '自提',
        value: 'SHOP_PACK'
    },
    {
        label: '堂食',
        value: 'SHOP_EAT'
    }
]