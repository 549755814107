import http from '../../helper/kyhttp';
const request = http.request;

let getTShopStatistic = (data) => request(`/team/getShopStatistic`, 1, {
    ...data
})

let getSShopStatistic = (data) => request(`/shop/getShopStatistic`, 1, {
    ...data
})

let getSRanking = (data) => request(`/shop/getRanking`, 1, {
    ...data
})

let getTRanking = (data) => request(`/team/getRanking`, 1, {
    ...data
})

let getTTradingTrends = (data) => request(`/team/getTradingTrends`, 1, {
    ...data
})

let reconciliation = (data) => http.request(`/team/reconciliation`, 1, {
    ...data
})

let searchReconciliation = (data) => http.request(`/team/searchReconciliation`, 1, {
    ...data
})

let lodownRequest = (data) => http.request(`/team/lodownRequest`, 1, {
    ...data
})

export default {
    getTShopStatistic,
    getSShopStatistic,
    reconciliation,
    searchReconciliation,
    lodownRequest,
    getSRanking,
    getTRanking,
    getTTradingTrends
}