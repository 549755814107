/* eslint-disable no-empty */
import {
    createApp
} from 'vue';

//全局样式
import './assets/css/common.css';
//全局颜色
import './assets/css/color.css';

import App from './App.vue';
const app = createApp(App);

//路由导航

import router from './routes.js';

//路由过滤器
import filter from './filter.js';
router.beforeEach(filter);

app.use(router);

app.mount('#app');