import { getToken, getIdentify, removeToken, setIdentify } from './utils/storage'
import { debounce } from "./utils/util";

let solve = (to, from, next) => {
    if (to.name) document.title = to.name + ' - 快意校园';
    else document.title = '快意校园对账后台';

    const token = getToken()
    const i = getIdentify();
    if (to.path == '/login') {
        console.log('come login', new Date());
        if (!token) next();
        else next('/');
    }
    else if (to.path == '/') {
        if (!token || !i) {
            removeToken();
            next('/login');
        }
        if (i.workerRole == 'TEAM' || i.role == 'ADMIN') {
            next('/home');
            if (i.workerShopId) {
                i.workerShopId = '';
                setIdentify(i);
                const d = debounce(() => { location.reload() }, 50)
                d()
            }
            return
        }
        else if (i.workerRole == 'SHOP') {
            next(`/shop/${i.workerShopId}`);
            return
        }
    } else if (to.path == '/identify') {
        if (i && i.role == 'ADMIN') {
            next('/home');
            return
        }
        return next();
    }
    else {
        if (!token || !i) {
            removeToken();
            next('/login');
        }
        next()
    }
}

export default solve;