/**
 * 公式计算转换
 * 
 * @author kun
 */
class Operation {
    constructor() {
        this.str = [];
        this.stack = [];
    }

    /**
     * 
     * @param {Array} formula 
     * @returns 
     */
    invert(formula) {
        if (!formula.length) return;
        const priority = {
            '+': 1,
            '-': 1,
            '*': 2,
            '/': 2,
        };
        for (let i = 0; i < formula.length; i++) {
            const char = formula[i]
            if (!isNaN(char)) {
                this.str.push(char);
            }

            else if ((priority[char] || char === "(")) {
                if (char !== "(" && this.stack.length) {
                    let j = this.stack.length - 1;
                    while (j >= 0) {
                        //遇到栈中括号，直接添加符号
                        if (this.stack[j] === "(") break;
                        //符号优先级大于栈中，直接添加
                        else if (priority[char] > priority[this.stack[j]]) break;
                        else {
                            this.str.push(this.stack.pop());
                            j--;
                        }
                    }
                }
                this.stack.push(char)
            }
            else if (char === ")") {
                let j = this.stack.length - 1
                while (j >= 0) {
                    const del = this.stack.pop();
                    if (del === "(") {
                        break;
                    }
                    this.str.push(del)
                    j--;
                }
            }
        }

        while (this.stack.length > 0) {
            this.str.push(this.stack.pop());
        }

        for (let i = 0; i < this.str.length; i++) {
            const char = this.str[i]
            if (!isNaN(char)) {
                this.stack.push(char);
            } else {
                const num1 = this.stack.pop();
                const num2 = this.stack.pop();

                const result = this.calculate(num1, num2, char);
                this.stack.push(result);
            }

        }
        return isNaN(this.stack[0]) ? `计算失败: ${formula.join('')}` : this.stack[0];
    }

    //加法
    accAdd(arg1, arg2) {
        let r1, r2, m, n
        try {
            r1 = arg1.toString().split('.')[1].length;
        } catch (e) {
            r1 = 0;
        }
        try {
            r2 = arg2.toString().split('.')[1].length;
        } catch (e) {
            r2 = 0;
        }
        m = Math.pow(10, Math.max(r1, r2));
        n = r1 >= r2 ? r1 : r2
        return +((arg1 * m + arg2 * m) / m).toFixed(n)
    }

    //减法
    accSub(arg1, arg2) {
        let r1, r2, m, n
        try {
            r1 = arg1.toString().split('.')[1].length;
        } catch (e) {
            r1 = 0;
        }
        try {
            r2 = arg2.toString().split('.')[1].length;
        } catch (e) {
            r2 = 0;
        }
        m = Math.pow(10, Math.max(r1, r2));
        n = r1 >= r2 ? r1 : r2
        return +((arg1 * m - arg2 * m) / m).toFixed(n)
    }

    //乘法
    accMul(arg1, arg2) {
        var m = 0,
            s1 = arg1.toString(),
            s2 = arg2.toString();
        try {
            m += s1.split(".")[1].length
        } catch (e) { }
        try {
            m += s2.split(".")[1].length
        } catch (e) { }
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
    }

    //除法
    accDiv(arg1, arg2) {
        var t1 = 0,
            t2 = 0,
            r1, r2;
        try {
            t1 = arg1.toString().split(".")[1].length
        } catch (e) { }
        try {
            t2 = arg2.toString().split(".")[1].length
        } catch (e) { }
        r1 = Number(arg1.toString().replace(".", ""))
        r2 = Number(arg2.toString().replace(".", ""))
        let result = this.accMul((r1 / r2), Math.pow(10, t2 - t1));
        return isNaN(result) ? 0 : result;
    }

    /**
     * 定义运算方法
     * @param {number} num1 
     * @param {number} num2 
     * @param {string} operator 运算符
     */
    calculate(num1, num2, operator) {
        const operators = {
            '+': (num1, num2) => this.accAdd(num2, num1),
            '-': (num1, num2) => this.accSub(num2, num1),
            '*': (num1, num2) => this.accMul(num2, num1),
            '/': (num1, num2) => this.accDiv(num2, num1)
        };

        const result = operators[operator](Number(num1), Number(num2));
        return result
    }
}

export default Operation