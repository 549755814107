import http from '../../helper/kyhttp';
import util from "../../util";

async function search(data) {
    try {
        const r = await http.request('/shop/order/search', 1, data);
        return r.data;
    } catch (e) {
        throw (e);
    }

}


export default {
    search,
}