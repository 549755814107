import config from '../config';
import Storage from "../storage";

/**
 * 
 * @param {*} token token
 * @param {*} group group
 */
let setToken = (token, group, nocache) => {
    if (!token) {
        config.TOKEN = '';
        config.aes.SECRETKEY = '';
        sessionStorage.removeItem("t");
    } else {
        config.TOKEN = token
        config.aes.SECRETKEY = token.substr(0, 12);
        if (nocache) return;
        let d;
        if (!group) {
            let cache = Storage.getGroup(); //用户组别数据和基本权益
            cache = cache ? cache : {};
            try {
                cache = JSON.parse(cache);
                group = cache;
            } catch (_e) {
                if (config.server.LOG) console.error("[TOKEN-ERROR]", 'CACHE', cache, "RESERT TOKEN");
            }
        }
        d = new Date().getTime() + 3600000 * (group.ab < 1 ? 0.3 : 5.0);
        sessionStorage.setItem("t-t", d);
    }
}

export default setToken;