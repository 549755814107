/* unplugin-vue-components disabled */import { defineComponent } from "vue";
import { ElConfigProvider } from "element-plus";
import 'element-plus/es/components/config-provider/style/css'

import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default defineComponent({
  name: "App",
  components: {
    ElConfigProvider
  },
  setup() {
    return {
      locale: zhCn
    };
  },
  created() {
    const debounce = (fn, delay) => {
      let timer = null;
      return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          fn.apply(context, args);
        }, delay);
      };
    };
    const _ResizeObserver = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
      constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
      }
    };
  }
});