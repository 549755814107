import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'
import Operation from "./operation";
import Admin from "./api/admin/admin"
import util from './util';

/**
 * 总表
 * @param {*} jsonData 
 * @param {*} fileName 
 * @param {*} school 
 * @param {*} startDate 
 * @param {*} endDate 
 * @returns 
 */
const totalExcel = (jsonData, fileName = "exportCSV.csv", school, startDate, endDate) => {
    if (!jsonData || jsonData.length == 0) {
        return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(fileName)
    worksheet.addRow([`单位名称：${school}`])
    const _1 = worksheet.getRow(1)
    _1.font = {
        bold: true,
        color: { argb: 'FF000000' },
        size: 16
    }
    worksheet.mergeCells(`A1:D1`)

    let time
    if (startDate == endDate) time = `${startDate}`;
    else time = `${startDate} 至 ${endDate}`;
    const timeCell = worksheet.addRow([time])
    timeCell.font = {
        bold: true,
        size: 13
    }
    for (const index in jsonData) {
        if (!jsonData[index] || (jsonData[index] && !jsonData[index].length)) continue;

        const cell = worksheet.addRow(Object.keys(jsonData[index][0]))
        cell.font = {
            bold: true,
            color: { argb: 'FF000000' },
            size: 12
        }
        for (let i = 0; i < jsonData[index].length; i++) {
            const item = jsonData[index][i];
            const _cell = worksheet.addRow(Object.values(item));
            _cell.font = {
                size: 11
            }
        }

        if (index < jsonData.length - 1) {
            worksheet.addRow([""]);
            worksheet.addRow([""]);
        }
    }

    _wprksheetStyle(worksheet, false, "center")

    worksheet.columns[0] = { width: 40 }
    _download(workbook, school + fileName, startDate, endDate);
}

/**
 * 明细计算
 * @param {object} obj 
 * @param {string} name 
 */
const detailsExcel = async (obj, name) => {

    const { data, school, startDate, endDate, locationId } = obj

    const flag = await request(locationId);
    if (flag) return;
    const _name = '快意校园_' + school + '_' + name

    const workbook = new ExcelJS.Workbook();
    const temp = _nameMapping(locationId, name);

    const worksheet = workbook.addWorksheet(temp)
    worksheetInit(worksheet, school, startDate, endDate, [`A1:F1`, `B2:C2`, `D2:E2`], _name)
    worksheet.addRow(['商圈', '店铺', '门店地址', '结算金额-外卖', '结算金额-自提', '合计金额']); //设置第三行表格元素列表

    let index = 0;
    for (let i = 0; i < data.length; i++) {
        const it = data[i]
        if (locationId && locationId === 104 && it['商圈'] === `美食街`) continue;
        if (it['商圈'] === '--') continue;
        let temp = it['商圈']
        temp = _nameMapping(locationId, temp);
        worksheet.addRow([temp, it['店铺'], it['门店地址'], it['结算金额-外卖'], it['结算金额-自提'], { formula: `SUM(D${index + 4}:E${index + 4})` }]);
        index++;
    }

    worksheet.addRow(['--', '--', '合计', { formula: `SUM(D${4}:D${index + 3})` }, { formula: `SUM(E${4}:E${index + 3})` }, { formula: `SUM(F4:F${index + 3})` }]);

    _wprksheetStyle(worksheet, true, "center");

    const columnC = worksheet.getColumn('C');
    columnC.width = 45;

    _download(workbook, _name, startDate, endDate);

}

const detailsExcelOneByOne = async (obj, name, businesses) => {
    const { data, school, startDate, endDate, locationId } = obj
    const flag = await request(locationId);
    if (flag) return;
    const _name = '快意校园_' + school + '_' + name

    const _array = [];
    businesses.map(it => _array.push(it.title));
    const workbook = new ExcelJS.Workbook();
    for (let i = 0; i < _array.length; i++) {
        let index = 0;

        const temp = _nameMapping(locationId, _array[i]);
        const worksheet = workbook.addWorksheet(temp);

        const _name = '快意校园_' + school + '_' + temp + '_' + name
        worksheetInit(worksheet, school, startDate, endDate, [`A1:F1`, `B2:C2`, `D2:E2`], _name)
        worksheet.addRow(['商圈', '店铺', '门店地址', '结算金额-外卖', '结算金额-自提', '合计金额',]); //设置第三行表格元素列表
        for (let j = 0; j < data.length; j++) {
            const it = data[j];
            if (it['商圈'] !== _array[i]) continue;
            let temp = it['商圈'];
            temp = _nameMapping(locationId, temp);
            worksheet.addRow([temp, it['店铺'], it['门店地址'], it['结算金额-外卖'], it['结算金额-自提'], { formula: `SUM(D${index + 4}:E${index + 4})` }]);
            index++;
        }
        worksheet.addRow(['--', '--', '合计', { formula: `SUM(D${4}:D${index + 3})` }, { formula: `SUM(E${4}:E${index + 3})` }, { formula: `SUM(F4:F${index + 3})` }]);
        _wprksheetStyle(worksheet, true, "center")

        const columnC = worksheet.getColumn('C');
        columnC.width = 45;
    }

    _download(workbook, _name, startDate, endDate);


}

/**
 * 汇总计算 
 * @param {object} obj
 * @param {string} name
 **/
const summaryExcel = async (obj, name) => {
    const { data, school, startDate, endDate, locationId } = obj
    const flag = await request(locationId);
    if (flag) return;
    const _name = '快意校园_' + school + '_' + name
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(name)
    worksheet.addRow([_name]); //设置第一行表格
    worksheet.addRow([`${startDate} 至 ${endDate}`, `单位名称：${school}`, '', '货币单位：元', ' ']); //设置第二行表格
    mergeCells(worksheet, [`A1:E1`, `B2:C2`, `D2:E2`])
    const _1 = worksheet.getCell('A1');
    _1.font = { bold: true, size: 13 };
    worksheet.addRow(['商圈', '结算金额-外卖', '结算金额-自提', '合计金额', '学校到账金额']); //设置第三行表格元素列表

    let map = new Map()
    const operation = new Operation();
    for (let i = 0; i < data.length; i++) {
        const it = data[i]
        let name = it['商圈'];
        if (locationId && locationId === 104 && name === `美食街`) continue;
        if (it['商圈'] === '--') continue;

        name = _nameMapping(locationId, name);

        if (!map.get(name)) map.set(name, { '商圈': name, '结算金额-外卖': it['结算金额-外卖'], '结算金额-自提': it['结算金额-自提'] });
        else {
            const obj = map.get(name)
            obj['结算金额-外卖'] = operation.accAdd(obj['结算金额-外卖'], it['结算金额-外卖']);
            obj['结算金额-自提'] = operation.accAdd(obj['结算金额-自提'], it['结算金额-自提']);
        }
    }
    const rowArray = Array.from(map.values());
    rowArray.forEach((it, index) => {
        worksheet.addRow([it['商圈'] + ' 汇总', it['结算金额-外卖'], it['结算金额-自提'], { formula: `SUM(B${index + 4}:C${index + 4})` }, ''])
    });

    worksheet.mergeCells(`E4:E${rowArray.length + 3}`);

    worksheet.addRow(['总计 汇总', { formula: `SUM(B4:B${rowArray.length + 3})` }, { formula: `SUM(C4:C${rowArray.length + 3})` }, { formula: `SUM(B${rowArray.length + 4}:C${rowArray.length + 4})` }, '']);

    _wprksheetStyle(worksheet, true, "center")

    _download(workbook, _name, startDate, endDate);

    map = null;
}

//交易对账明细表
const tradingDetailTable = (obj, name) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(name)

    const { data, school, startDate, endDate, locationId } = obj;

    const _name = '快意校园_' + school + '_' + name
    const arr = ['商圈', '门店名称', '门店地址', '支付交易总金额(元)', '支付成功总笔数(笔)', '退款成功总金额(元)', '退款交易总笔数(笔)', '合计交易总金额(元)', '合计交易总笔数(笔)', '平台交易手续费(元)', '微信交易手续费(0.2%)', '结算总笔数(笔)', '结算金额(元)'];
    worksheet.addRow([_name]); //设置第一行表格
    worksheet.addRow([`${startDate} 至 ${endDate}`, '', `单位名称：${school}`]); //设置第二行表格
    worksheet.addRow(arr);
    const _1 = worksheet.getCell('A1');
    _1.font = { bold: true, size: 13 };
    mergeCells(worksheet, [`A1:M1`, 'A2:B2', 'C2:M2']);

    const _2 = worksheet.getRow(2);
    _2.font = { bold: true };

    const _3 = worksheet.getRow(3);
    _3.font = { bold: true };

    const operation = new Operation()
    for (let i = 0; i < data.length; i++) {
        const it = data[i];
        let name = it['商圈'];
        if (locationId && locationId === 104 && it['商圈'] === `美食街`) continue;
        if (it['商圈'] === '--') continue;
        name = _nameMapping(locationId, name);
        const w_total_goods = operation.accAdd(it['外卖-商品金额'], it['外卖-打包费']);
        const z_total_goods = operation.accAdd(it['自提-商品金额'], it['自提-打包费']);
        const total_goods = operation.accAdd(w_total_goods, z_total_goods) //支付交易总金额(元)

        const count = operation.accAdd(it['外卖单量'], it['自提单量']); // 支付成功总笔数(笔)

        const w_loss_goods = operation.accAdd(it['外卖退款金额'], it['外卖-打包费损失']);
        const z_loss_goods = it['自提退款金额'];
        const loss_goods = operation.accAdd(w_loss_goods, z_loss_goods); //退款成功总金额(元)

        const loss_count = operation.accAdd(it['外卖退款单量'], it['自提退款单量']); //退款交易总笔数(笔)

        const total_sales = operation.accSub(total_goods, loss_goods); //合计交易总金额(元)

        const w_count_sales = operation.accSub(it['外卖单量'], it['外卖退款单量']);
        const z_count_sales = operation.accSub(it['自提单量'], it['自提退款单量']);
        const count_sales = operation.accAdd(w_count_sales, z_count_sales); //合计交易总笔数(笔)

        const w_total_payment = operation.accSub(it['外卖-手续费'], it['外卖-手续费损失']);
        const z_total_payment = operation.accSub(it['自提-手续费'], it['自提-手续费损失']);
        const total_payment = operation.accAdd(w_total_payment, z_total_payment) //总微信交易手续费(元)

        const count_salary = operation.accAdd(it['外卖结算单量'], it['自提结算单量']); //结算总笔数(笔)

        const total_salary = operation.accAdd(it['结算金额-外卖'], it['结算金额-自提']) //结算金额(元)

        const w_total_loss_goods = operation.accAdd(it['外卖退款金额'], it['外卖-打包费损失']);
        const z_total_loss_goods = operation.accAdd(it['自提退款金额'], it['自提-打包费损失']);
        const total_loss_goods = operation.accAdd(w_total_loss_goods, z_total_loss_goods);
        const a = operation.accSub(total_goods, total_loss_goods)
        const b = operation.accSub(a, total_salary)
        const c = operation.accSub(b, total_payment) //平台交易手续费(元)

        const address = it['门店地址'] ? it['门店地址'] : ''
        let temp = [name, it['店铺'], address, total_goods, count, -loss_goods, loss_count, total_sales, count_sales, c, total_payment, count_salary, total_salary];
        worksheet.addRow(temp);
    }
    _wprksheetStyle(worksheet, true, "center")
    _download(workbook, _name, startDate, endDate);
}


//自定义下载
const e5 = async (obj, name, businesses, flag1, flag2) => {
    const { data, school, startDate, endDate, locationId } = obj;
    const workbook = new ExcelJS.Workbook();
    const flag = await request(locationId);
    if (flag) return;
    if (flag1) {
        const _array = [];
        businesses.map(it => _array.push(it.title));
        for (let i = 0; i < _array.length; i++) {
            const name = _nameMapping(locationId, _array[i]);
            const worksheet = workbook.addWorksheet(name);

            let totalLength = 0;
            worksheet.addRow(Object.keys(data[0]));
            for (let j = 0; j < data.length; j++) {
                const it = data[j];
                if (it['商圈'] !== _array[i]) continue;
                let name = it['商圈'];
                name = _nameMapping(locationId, name);
                worksheet.addRow(Object.values(it));
                totalLength += 1;
            }
            const row = lastRow(data[0], totalLength + 1)
            worksheet.addRow(row);
            _wprksheetStyle(worksheet, true, "center")
        }
    } else if (!flag1) {
        const worksheet = workbook.addWorksheet(name);

        worksheet.addRow(Object.keys(data[0]));


        for (let i = 0; i < data.length; i++) {
            const temp = []
            const item = data[i];
            if (data[i]['商圈'] === '--') continue;
            for (const key in item) {
                temp.push(item[key]);
            }
            worksheet.addRow(temp);

        }

        const row = lastRow(data[0], data.length)

        worksheet.addRow(row);
        _wprksheetStyle(worksheet, true, "center")
    }
    _download(workbook, '店铺对账', startDate, endDate);

    function lastRow(data, length) {
        const letterMap = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
        let a1, head;
        if (data['日期']) { a1 = ['--', '--', '合计']; head = 3; }
        else { a1 = ['--', '合计']; head = 2; }

        const one = Object.keys(data)

        let a2 = []

        for (let index = head; index < one.length; index++) {
            a2.push({ formula: `SUM(${letterMap[index]}2:${letterMap[index]}${length})` })
        }

        return [...a1, ...a2]
    }
}

//表头初始化
const worksheetInit = (worksheet, school, startDate, endDate, arr, _name) => {
    worksheet.addRow([_name]); //设置第一行表格
    worksheet.addRow([`${startDate} 至 ${endDate}`, `单位名称：${school}`, '', '货币单位：元', ' ', ' ']); //设置第二行表格
    mergeCells(worksheet, arr)
    const _1 = worksheet.getCell('A1');
    _1.font = { bold: true, size: 13 };
}

//合并单元格
const mergeCells = (worksheet, cells) => {
    for (let i = 0; i < cells.length; i++) {
        worksheet.mergeCells(cells[i])
    }
}

//表格样式
const _wprksheetStyle = (worksheet, border, center = "center") => {
    const totalColumnsWithData = worksheet.columns.length;
    const columns = []
    for (let i = 0; i < totalColumnsWithData; i++) {
        columns.push({ width: 25 });
    }
    worksheet.columns = columns;  //设置单元格宽度列表;
    worksheet.eachRow((row) => {
        row.eachCell((cell) => {
            cell.alignment = { vertical: 'middle', horizontal: center };
            if (border) {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            }
        });
    });

}

//餐厅名称映射
const _nameMapping = (locationId, name) => {
    if (locationId && locationId === 104) {
        if (name === `老兵美食`) name = `布芳园老兵`;
        else if (name === `文院(老兵美食)`) name = `蓝青园老兵`;
        else if (name === `布芳园`) name = `布芳园新志和`;
        else if (name === `蓝青园`) name = `蓝青园翼博`;
        name += `餐厅`
    }
    return name;
}

//表格下载
const _download = (workbook, name, startDate, endDate) => {
    startDate = getDate(startDate);
    endDate = getDate(endDate);
    if (startDate == endDate) name = name + `${startDate}`;
    else name = name + `${startDate}_${endDate}`;
    workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
        })
        console.log("导出成功！");
        FileSaver.saveAs(blob, `${name} .xlsx`);
    })

    function getDate(date) {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = d.getMonth() + 1 >= 10 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
        const day = d.getDate() >= 10 ? d.getDate() : '0' + d.getDate();
        return `${year}-${month}-${day}`;
    }
}

/**
 * 下载商圈自检
 * @param {*} workbook 
 * @param {*} name 
 * @param {*} startDate 
 * @param {*} endDate 
 */
const request = async (locationId) => {
    if (locationId !== 104) return false;
    const res = await Admin.lodownRequest({});
    if (res.data.code !== 200) {
        util.show.toptip(res.data.message, 'red');
        return true;
    }
    return false;
}
export default { detailsExcel, totalExcel, detailsExcelOneByOne, summaryExcel, e5, tradingDetailTable }