import { decrypt, encryption } from "./decrypt";

export const getIdentify = () => {
    return decrypt(sessionStorage.getItem("t-i"))
}

export const setIdentify = (val) => {
    sessionStorage.setItem("t-i", encryption(val));
}

export const getGroup = () => {
    return decrypt(localStorage.getItem("group"));
}

export const setGroup = (val) => {
    localStorage.setItem("group", encryption(val));
}

export const getTreeData = () => {
    return decrypt(sessionStorage.getItem("tree"));
}

export const setTreeData = (val) => {
    sessionStorage.setItem("tree", encryption(val));
}

export const getIsAd = () => {
    return decrypt(sessionStorage.getItem("isAd"));
}

export const setIsAd = (val) => {
    sessionStorage.setItem("isAd", encryption(val));
}

export const getShops = () => {
    return JSON.parse(sessionStorage.getItem("shops"));
}

export const setShops = (val) => {
    sessionStorage.setItem("shops", JSON.stringify(val));
}

export const getDate = () => {
    return sessionStorage.getItem("date")
}

export const setDate = (val) => {
    sessionStorage.setItem("date", val);
}

export const getToken = () => {
    return sessionStorage.getItem('t')
}

export const setToken = (val) => {
    sessionStorage.setItem('t', val)
}

export const removeToken = () => {
    sessionStorage.removeItem('t')
}

export const getFormula = (val) => {
    return localStorage.getItem(val)
}

export const setFormula = (key, val) => {
    localStorage.setItem(key, val)
}

export const removeFormula = (key) => {
    localStorage.removeItem(key);
}
export default {
    getIdentify,
    setIdentify,
    getGroup,
    setGroup,
    getTreeData,
    setTreeData,
    getIsAd,
    setIsAd,
    getShops,
    setShops,
    getDate,
    setDate,
    getToken,
    setToken,
    getFormula,
    setFormula,
    removeFormula,
}