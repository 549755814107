import "core-js/modules/es.array.push.js";
export default {
  props: {
    formula: {
      type: Array,
      default: false
    },
    closeFastAddShow: {
      type: Function,
      default: false
    }
  },
  data() {
    return {
      drawer: true
    };
  },
  methods: {
    add(obj) {
      this.formula.push(obj);
      this.closeFastAddShow();
    },
    amountTotal() {
      const obj = {
        item: [{
          name: "type",
          value: "结算金额-外卖",
          show: false
        }, {
          name: "symbol",
          value: "+"
        }, {
          name: "type",
          value: "结算金额-自提",
          show: false
        }],
        formulaName: "合计结算金额",
        inputName: "合计结算金额",
        canDownLoad: true
      };
      this.add(obj);
    },
    packCompute() {
      const obj = {
        item: [{
          name: "type",
          value: "外卖-打包费",
          show: false
        }, {
          name: "symbol",
          value: "+"
        }, {
          name: "type",
          value: "自提-打包费",
          show: false
        }, {
          name: "symbol",
          value: "-"
        }, {
          name: "type",
          value: "自提-打包费损失",
          show: false
        }, {
          name: "symbol",
          value: "-"
        }, {
          name: "type",
          value: "外卖-打包费损失",
          show: false
        }, {
          name: "symbol",
          value: "-"
        }, {
          name: "type",
          value: "外卖单量",
          show: false
        }, {
          name: "symbol",
          value: "*"
        }, {
          name: "type",
          value: "40",
          show: false
        }, {
          name: "symbol",
          value: "/"
        }, {
          name: "type",
          value: "100",
          show: false
        }, {
          name: "symbol",
          value: "-"
        }, {
          name: "type",
          value: "自提单量",
          show: false
        }, {
          name: "symbol",
          value: "*"
        }, {
          name: "type",
          value: "40",
          show: false
        }, {
          name: "symbol",
          value: "/"
        }, {
          name: "type",
          value: "100",
          show: false
        }],
        formulaName: "包含打包费收益(已扣除0.4元垃圾处理费)",
        inputName: "包含打包费收益(已扣除0.4元垃圾处理费)",
        canDownLoad: true
      };
      this.add(obj);
    },
    Transactions() {
      const obj = {
        item: [{
          name: "type",
          value: "外卖-商品金额",
          show: false
        }, {
          name: "symbol",
          value: "+"
        }, {
          name: "type",
          value: "外卖-打包费",
          show: false
        }, {
          name: "symbol",
          value: "+"
        }, {
          name: "type",
          value: "外卖配送费",
          show: false
        }, {
          name: "symbol",
          value: "-"
        }, {
          name: "type",
          value: "外卖-打包费损失",
          show: false
        }, {
          name: "symbol",
          value: "-"
        }, {
          name: "type",
          value: "外卖退款金额",
          show: false
        }, {
          name: "symbol",
          value: "-"
        }, {
          name: "type",
          value: "外卖配送费损失",
          show: false
        }, {
          name: "symbol",
          value: "+"
        }, {
          name: "type",
          value: "自提-商品金额",
          show: false
        }, {
          name: "symbol",
          value: "+"
        }, {
          name: "type",
          value: "自提-打包费",
          show: false
        }, {
          name: "symbol",
          value: "-"
        }, {
          name: "type",
          value: "自提-打包费损失",
          show: false
        }, {
          name: "symbol",
          value: "-"
        }, {
          name: "type",
          value: "自提退款金额",
          show: false
        }],
        formulaName: "流水（包含配送费）",
        inputName: "流水（包含配送费）",
        canDownLoad: true
      };
      this.add(obj);
    },
    turnover() {
      const obj = {
        item: [{
          name: "type",
          value: "外卖-商品金额",
          show: false
        }, {
          name: "symbol",
          value: "+"
        }, {
          name: "type",
          value: "自提-商品金额",
          show: false
        }, {
          name: "symbol",
          value: "+"
        }, {
          name: "type",
          value: "外卖-打包费",
          show: false
        }, {
          name: "symbol",
          value: "+"
        }, {
          name: "type",
          value: "自提-打包费",
          show: false
        }],
        formulaName: "营业额（不包含配送费）",
        inputName: "营业额（不包含配送费）",
        canDownLoad: true
      };
      this.add(obj);
    }
  }
};