import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e2e55178"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "btn"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_drawer = _resolveComponent("el-drawer");
  return _openBlock(), _createBlock(_component_el_drawer, {
    modelValue: $data.drawer,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.drawer = $event),
    "before-close": $props.closeFastAddShow,
    title: "默认公式"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
      onClick: $options.amountTotal
    }, {
      default: _withCtx(() => [_createTextVNode(" 合计结算金额 ")]),
      _: 1
    }, 8, ["onClick"]), _createVNode(_component_el_button, {
      onClick: $options.packCompute
    }, {
      default: _withCtx(() => [_createTextVNode(" 包含打包费收益(已扣除0.4元垃圾处理费) ")]),
      _: 1
    }, 8, ["onClick"]), _createVNode(_component_el_button, {
      onClick: $options.Transactions
    }, {
      default: _withCtx(() => [_createTextVNode("流水（包含配送费）")]),
      _: 1
    }, 8, ["onClick"]), _createVNode(_component_el_button, {
      onClick: $options.turnover
    }, {
      default: _withCtx(() => [_createTextVNode("营业额（不包含配送费）")]),
      _: 1
    }, 8, ["onClick"])])]),
    _: 1
  }, 8, ["modelValue", "before-close"]);
}