import config from './config';
import CryptoJS from 'crypto-js';

/**
 * 
 * 数据加密
 */
export function encryption(data) {
    if(!data) return undefined
    return CryptoJS.DES.encrypt(JSON.stringify(data), config.cryptoJSKey).toString();
}

/**
 * 
 * 数据解密
 */
export function decrypt(data) {
    if (!data) return undefined
    const res = JSON.parse(CryptoJS.DES.decrypt(data, config.cryptoJSKey).toString(CryptoJS.enc.Utf8))
    return res
}