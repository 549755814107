import config from '../config';
import CryptoJS from 'crypto-js';
import util from "../util";
import axios from "axios";
import { setToken, getToken, removeToken } from '../storage';
import router from '../../routes';
let requestTimeout;
/**
 * 
 * @param {*} api API路径
 * @param {*} method 0GET 1POST
 * @param {*} data 提交数据
 */
let request = (api, method, data) => {
    if (config.server.LOG) console.log("[API-SEND.]", api)
    return new Promise(function (resolve, reject) {
        let headers = {
            'content-type': method ? 'application/json' : 'application/x-www-form-urlencoded' //application/json charset=utf-8;
        };
        const token = getToken()
        if (token) headers['x-access-token'] = token; //注释该行，没有通信凭证
        headers['version'] = config.server.VERSION;
        headers['hardware-concurrency'] = config.server.hardwareConcurrency
        headers['screen'] = config.server.screen
        headers['platform'] = config.server.platform
        axios({
            url: config.server.URL + api,
            data: method ? JSON.stringify(data) : Object.keys(data).map(function (key) {
                return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
            }).join("&"),
            method: method ? 'post' : 'get',
            headers: headers,
            timeout: 10000,//ms
        }).then(res => {
            if (res.data.code != 0) {
                if (res.data.code == 5001) {
                    // 更新token
                    sessionStorage.clear()
                    if (!requestTimeout) {
                        requestTimeout = setTimeout(() => {
                            clearTimeout(requestTimeout);
                            requestTimeout = null;
                        }, 1000)
                    } else {
                        return reject('');
                    }
                    router.push({
                        path: '/login'
                    })
                }
                else if (res.data.code == 10201) {
                    // 更新token
                    removeToken(); //全局变量
                } else if (res.data.code == 5002) {
                    // 更新token
                    removeToken(); //全局变量
                }
                util.show.toptip(res.data.message, 'red');
                reject(res.data);
            } else {
                if (config.server.LOG) console.log("[API-RECEIVE]", api, res.data);
                const secretKey = config.cryptoJSKey;
                res.data.data = JSON.parse(CryptoJS.DES.decrypt(res.data.data, secretKey).toString(CryptoJS.enc.Utf8))
                if (res.data.data.refresh_token) {
                    setToken(res.data.data.refresh_token)
                    delete res.data.data.refresh_token;
                }
                resolve(res.data);
            }
        }).catch(err => {
            console.error(err);
            //if (config.server.LOG) console.error("[API-ERROR1.]", api, err);
            if (err.code === 'ERR_NETWORK') {
                if (!requestTimeout) {
                    util.show.toptip('无法连接到服务器请稍后重试', 'red');
                    requestTimeout = setTimeout(() => {
                        clearTimeout(requestTimeout);
                        requestTimeout = null;
                    }, 500)
                } else {
                    return reject('');
                }
            }
            else if (err.code === "ERR_BAD_REQUEST") {
                util.show.toptip(`'${api}' IS Not Found, Check Your Url`, 'red', 5000);
            }
            else util.show.toptip(err.message, 'red');
            reject(err);
            console.error(
                {
                    status: -1,
                    err: err,
                    sign: {
                        api,
                        data
                    }
                }
            );
        })
    })
}

export default {
    request
}