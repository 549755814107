import kyhttp from '../../helper/kyhttp';
import { setTreeData, setShops, setToken, getTreeData } from '../../storage';

let identify = (obj) => {
    return new Promise((resolve, reject) => {
        kyhttp.request("/users/identify", 1, {
            obj
        }).then(res => {
            let childern = res.data.treeMenu
            setTreeData(childern)
            setShops(res.data.shops)
            setToken(res.data.token)
            resolve(childern[0].path)
        }).catch(e => {
            reject(e);
        })
    })
}

export default {
    identify
} 