import SetToken from "../helper/setToken";
import kyhttp from '../helper/kyhttp';
import { setTreeData, setIdentify, setToken, setGroup, setIsAd } from '../storage';

import { MD5 } from "crypto-js";

let loginWeb = (type, account, password) => {
    return new Promise((resolve, reject) => {
        kyhttp.request("/users/wxloginWeb", 1, {
            type: type,
            account: account,
            password: MD5(password).toString(),
            // password: '25f9e794323b453885f5181f1b624d0b'
        }).then(res => {
            const isAd = res.data.isAd
            if (isAd) {
                setTreeData(res.data.treeData)
                setIdentify(res.data.group[0])
            }
            setToken(JSON.stringify(res.data.token))
            //加密group数组
            setGroup(res.data.group)
            //加密权限
            setIsAd(isAd.toString())

            SetToken(res.data.token, res.data.group)
            resolve({
                isAd,
                group: res.data.group,
                path: isAd ? res.data.treeData[0].path : ''
            });
        }).catch(e => {
            //登录失败
            console.log('登录失败', e);
            reject(e);
        })
    })
}

export default {
    loginWeb
}